<template>
  <div class="box" v-loading="loading" style="min-width: 1765px;">
    <el-row :gutter="10" v-if="HomeFlag" v-loading="Typeloading">
      <el-col :span="8">
        <div class="ComprehensiveDataCard">
          <span class="title">综合数据查询</span>
          <el-button style="float: right; font-weight: bolder; margin: 0px 10px 0 0" type="text" icon="el-icon-thumb"
            @click="PageOpen('MG_ComprehensiveDataPage')">点击进入
          </el-button>
        </div>
        <el-card class="baseInfoCard" v-loading="MG_BaseInfoLoading">
          <div slot="header">
            <span>基础信息模块</span>
            <el-button style="float: right; font-weight: bolder; margin: 6px 10px 0 0" type="text" icon="el-icon-thumb"
              @click="PageOpen('MG_BaseInfoPage')">点击进入
            </el-button>
          </div>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-card class="dataStripe" style="background-color: orange">
                    <el-row>
                      <el-col :span="2"></el-col>
                      <el-col :span="6" style="text-align: center">
                        <i class="el-icon-office-building dataStripe_i"></i>
                      </el-col>
                      <el-col :span="16" style="text-align: center">
                        <span class="dataStripe_title">项目总面积</span>
                        <span class="dataStripe_value">{{ MG_BaseInfo.ProjectTotalArea }}㎡</span>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-card class="dataStripe" style="background-color: green">
                    <el-row>
                      <el-col :span="2"></el-col>
                      <el-col :span="6" style="text-align: center">
                        <i class="el-icon-house dataStripe_i"></i>
                      </el-col>
                      <el-col :span="16" style="text-align: center">
                        <span class="dataStripe_title">经营总面积</span>
                        <span class="dataStripe_value">{{ MG_BaseInfo.OperatingTotalArea }}㎡</span>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
              <el-row :gutter="10">
                <el-col :span="24">
                  <el-card class="dataStripe" style="background-color: mediumpurple">
                    <el-row>
                      <el-col :span="2"></el-col>
                      <el-col :span="6" style="text-align: center">
                        <i class="el-icon-school dataStripe_i"></i>
                      </el-col>
                      <el-col :span="16" style="text-align: center">
                        <span class="dataStripe_title">总计商铺面积</span>
                        <span class="dataStripe_value">{{ MG_BaseInfo.ShopTotalArea }}㎡</span>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="12">
              <EchartsIndex refId="coverageRateOptions" :option="coverageRateOptions" style="width: 100%; height: 280px"
                v-if="this.MG_BaseInfo.FAP_Shop && this.MG_BaseInfo.FAP_Shop.length > 0" />
              <el-empty description="暂无数据" v-else style="width: 100%; height: 280px"></el-empty>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-card style="margin-top: 20px; padding: 5px 0" v-if="this.MG_BaseInfo.FAP_Shop">
                <el-row style="text-align: right">
                  <el-col :span="16" style="
                      text-align: left;
                      text-indent: 10px;
                      font-size: 20px;
                      font-weight: bold;
                      line-height: 32px;
                    ">
                    <span v-if="formatCountOptionsRadioIs == 1">业态总计面积占比</span>
                    <span v-if="formatCountOptionsRadioIs == 2">业态总计数量占比</span>
                  </el-col>
                  <el-col :span="8">
                    <el-radio-group v-model="formatCountOptionsRadioIs" size="small" @change="changeType">
                      <el-radio-button :label="1"><i class="el-icon-s-home"></i>&nbsp;面积</el-radio-button>
                      <el-radio-button :label="2"><i class="el-icon-s-grid"></i>&nbsp;数量</el-radio-button>
                    </el-radio-group>
                  </el-col>
                </el-row>

                <div style="position: relative;">
                  <div style="position: absolute;left: 10px;top: 0;font-size: 12px;font-weight: bold; ">
                    <div v-html="formatCountOptionsNumView" v-if="formatCountOptionsRadioIs == 1">
                    </div>
                    <div v-html="formatCountOptionsNumView2" v-if="formatCountOptionsRadioIs == 2">
                    </div>
                  </div>
                  <EchartsIndex ref="formatCountOptions" refId="formatCountOptions" :option="formatCountOptions"
                    @onclick="formatCountOptionsClick" styleStr="width: 100%; height: 260px"
                    v-if="this.MG_BaseInfo.FAP_Shop && this.MG_BaseInfo.FAP_Shop.length > 0" />
                  <el-empty description="暂无数据" v-else style="width: 100%; height: 260px"></el-empty>
                </div>
              </el-card>
              <el-empty description="暂无数据" v-else style="width: 100%; height: 380px"></el-empty>
            </el-col>
          </el-row>
        </el-card>
      </el-col><!-- 左侧基础信息模块 -->
      <el-col :span="16"><!-- 右侧整个区块信息 -->
        <el-row :gutter="10"><!-- 技术信息模块和餐饮信息模块 -->
          <el-col :span="7">
            <el-card class="technicalModule" v-loading="MG_TechnicalDataInfoLoading">
              <div slot="header">
                <span>技术信息模块</span>
                <el-button style="float: right; font-weight: bolder; margin: 6px 10px 0 0" type="text"
                  icon="el-icon-thumb" @click="PageOpen('MG_TechnicalDataPage')">点击进入
                </el-button>
              </div>
              <el-card class="dataStripe" style="background-color: #068d74">
                <el-row>
                  <el-col :span="2"></el-col>
                  <el-col :span="6" style="text-align: center">
                    <i class="iconfont icon-leijiyongdianliang dataStripe_i"></i>
                  </el-col>
                  <el-col :span="16" style="text-align: center">
                    <span class="dataStripe_title">整体用电负荷平均指标</span>
                    <span class="dataStripe_value">{{
                      MG_TechnicalDataInfo.AvgLoadQuota_Electricity
                        ? MG_TechnicalDataInfo.AvgLoadQuota_Electricity.toFixed(2)
                        : 0
                    }}W/㎡</span>
                  </el-col>
                </el-row>
              </el-card>
              <el-card class="dataStripe" style="background-color: #f84d4d">
                <el-row>
                  <el-col :span="2"></el-col>
                  <el-col :span="6" style="text-align: center">
                    <i class="iconfont icon-refuhe dataStripe_i"></i>
                  </el-col>
                  <el-col :span="16" style="text-align: center">
                    <span class="dataStripe_title">整体热负荷平均指标</span>
                    <span class="dataStripe_value">{{
                      MG_TechnicalDataInfo.AvgLoadQuota_Heat
                        ? MG_TechnicalDataInfo.AvgLoadQuota_Heat.toFixed(2)
                        : 0
                    }}W/㎡</span>
                  </el-col>
                </el-row>
              </el-card>
              <el-card class="dataStripe" style="background-color: #7395d3">
                <el-row>
                  <el-col :span="2"></el-col>
                  <el-col :span="6" style="text-align: center">
                    <i class="iconfont icon-lengfuhe dataStripe_i"></i>
                  </el-col>
                  <el-col :span="16" style="text-align: center">
                    <span class="dataStripe_title">整体冷负荷平均指标</span>
                    <span class="dataStripe_value">{{
                      MG_TechnicalDataInfo.AvgLoadQuota_Cooling
                        ? MG_TechnicalDataInfo.AvgLoadQuota_Cooling.toFixed(2)
                        : 0
                    }}W/㎡</span>
                  </el-col>
                </el-row>
              </el-card>
            </el-card>
          </el-col>
          <el-col :span="17"><!-- 餐饮信息模块 -->
            <el-card class="cateringModule" v-loading="MG_CateringDataInfoLoading">
              <div slot="header">
                <span>餐饮信息模块</span>
                <el-button style="float: right; font-weight: bolder; margin: 6px 10px 0 0" type="text"
                  icon="el-icon-thumb" @click="PageOpen('MG_CateringDataPage')">点击进入
                </el-button>
                <el-button style="float: right; font-weight: bolder; margin: 6px 10px 0 0" type="text"
                  icon="el-icon-s-grid" @click="PageOpen('MG_ElectricityPage')">电气统计
                </el-button>

              </div>
              <el-row :gutter="10">
                <el-col :span="10">
                  <el-card class="dataStripe" style="background-color: #068d74">
                    <el-row>
                      <el-col :span="2"></el-col>
                      <el-col :span="6" style="text-align: center">
                        <i class="iconfont icon-leijiyongdianliang dataStripe_i"></i>
                      </el-col>
                      <el-col :span="16" style="text-align: center">
                        <span class="dataStripe_title">餐饮用电负荷平均指标</span>
                        <span class="dataStripe_value">{{
                          MG_CateringDataInfo.AvgLoadQuota_Electricity
                            ? MG_CateringDataInfo.AvgLoadQuota_Electricity.toFixed(2)
                            : 0
                        }}W/㎡</span>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-card class="dataStripe" style="background-color: #f84d4d">
                    <el-row>
                      <el-col :span="2"></el-col>
                      <el-col :span="6" style="text-align: center">
                        <i class="iconfont icon-refuhe dataStripe_i"></i>
                      </el-col>
                      <el-col :span="16" style="text-align: center">
                        <span class="dataStripe_title">餐饮热负荷平均指标</span>
                        <span class="dataStripe_value">{{
                          MG_CateringDataInfo.AvgLoadQuota_Heat
                            ? MG_CateringDataInfo.AvgLoadQuota_Heat.toFixed(2)
                            : 0
                        }}W/㎡</span>
                      </el-col>
                    </el-row>
                  </el-card>
                  <el-card class="dataStripe" style="background-color: #7395d3">
                    <el-row>
                      <el-col :span="2"></el-col>
                      <el-col :span="6" style="text-align: center">
                        <i class="iconfont icon-lengfuhe dataStripe_i"></i>
                      </el-col>
                      <el-col :span="16" style="text-align: center">
                        <span class="dataStripe_title">餐饮冷负荷平均指标</span>
                        <span class="dataStripe_value">{{
                          MG_CateringDataInfo.AvgLoadQuota_Cooling
                            ? MG_CateringDataInfo.AvgLoadQuota_Cooling.toFixed(2)
                            : 0
                        }}W/㎡</span>
                      </el-col>
                    </el-row>
                  </el-card>
                </el-col>
                <el-col :span="14">
                  <el-row :gutter="10">
                    <el-col :span="24">
                      <el-card class="dataStripe" style="background-color: #6665b8">
                        <el-row>
                          <el-col :span="2"></el-col>
                          <el-col :span="6" style="text-align: center">
                            <i class="iconfont icon-zhifutubiaoku_yangan-xian dataStripe_i"></i>
                          </el-col>
                          <el-col :span="16" style="text-align: center">
                            <span class="dataStripe_title">餐饮总排油烟量平均指标</span>
                            <span class="dataStripe_value">{{
                              MG_CateringDataInfo.AvgQuota_OilFumeDischarge
                                ? MG_CateringDataInfo.AvgQuota_OilFumeDischarge.toFixed(
                                  2
                                )
                                : 0
                            }}<span style="font-size: 18px">/换气次数</span></span>
                          </el-col>
                        </el-row>
                      </el-card>
                    </el-col>
                  </el-row>
                  <el-row :gutter="10">
                    <el-col :span="12">
                      <EchartsIndex refId="canyinCountOptions" :option="canyinCountOptions"
                        styleStr="width: 100%; height: 224px" v-if="this.MG_CateringDataInfo.ShopCountProportion" />
                      <el-empty description="暂无数据" v-else style="width: 100%; height: 224px"></el-empty>
                    </el-col>
                    <el-col :span="12">
                      <EchartsIndex refId="canyinAreaOptions" :option="canyinAreaOptions"
                        styleStr="width: 100%; height: 224px" v-if="this.MG_CateringDataInfo.ShopAreaProportion" />
                      <el-empty description="暂无数据" v-else style="width: 100%; height: 224px"></el-empty>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
        <el-row :gutter="10"><!-- 商铺变动原因以及工程合约信息部分 -->
          <el-col :span="12"><!-- 商铺变动原因 -->
            <el-card class="storeChangeModule" style="margin-top: 10px" v-loading="MG_ShopChangesDataInfoLoading">
              <el-row slot="header">
                <el-col :span="12">
                  <span>商铺变动原因模块</span>
                </el-col>
                <el-col :span="12">
                  <el-radio-group v-model="shopChangedIs" size="small" style="text-indent: 0">
                    <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;柱状图</el-radio-button>
                    <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;饼图</el-radio-button>
                  </el-radio-group>
                  <el-button style="font-weight: bolder; margin: 6px 10px 0 20px" type="text" icon="el-icon-thumb"
                    @click="PageOpen('MG_ShopChangesDataPage')">点击进入
                  </el-button>
                </el-col>
              </el-row>
              <el-row v-if="shopChangedIs == 1">
                <el-col>
                  <EchartsIndex refId="shopChangedBar" :option="shopChangedBar" styleStr="width: 100%;height: 270px;"
                    v-if="this.MG_ShopChangesDataInfo.SCRP_Shop" />
                  <el-empty description="暂无数据" style="width: 100%;height: 270px;" v-else></el-empty>
                </el-col>
              </el-row>
              <el-row v-if="shopChangedIs == 2">
                <el-col>
                  <EchartsIndex refId="shopChangedPie" :option="shopChangedPie" styleStr="width: 100%;height: 270px;"
                    v-if="this.MG_ShopChangesDataInfo.SCRP_Shop" />
                  <el-empty description="暂无数据" style="width: 100%;height: 270px;" v-else></el-empty>
                </el-col>
              </el-row>
            </el-card>
          </el-col>
          <el-col :span="12"><!-- 工程合约信息 -->
            <el-card class="ecModule" style="margin-top: 10px" v-loading="MG_EngineeringContractInfoLoading">
              <el-row slot="header">
                <el-col :span="12"><span>工程及合约信息模块</span></el-col>
                <el-col :span="12">
                  <el-radio-group v-model="formatProjectStatusIs" size="small" style="text-indent: 0">
                    <el-radio-button :label="1"><i class="el-icon-s-data"></i>&nbsp;柱状图</el-radio-button>
                    <el-radio-button :label="2"><i class="el-icon-s-help"></i>&nbsp;饼图</el-radio-button>
                  </el-radio-group>
                  <el-button style="font-weight: bolder; margin: 6px 10px 0 20px" type="text" icon="el-icon-thumb"
                    @click="PageOpen('MG_EngineeringContractPage')">点击进入
                  </el-button>
                </el-col>
              </el-row>
              <el-row v-if="formatProjectStatusIs == 1">
                <el-col>
                  <EchartsIndex refId="formatProjectStatusBar" :option="formatProjectStatusBar"
                    styleStr="width: 100%;height: 270px;"
                    v-if="this.MG_EngineeringContractInfo.ECMP_Shop && this.MG_EngineeringContractInfo.ECMP_Shop.length > 0" />
                  <el-empty description="暂无数据" v-else style="width: 100%; height: 270px"></el-empty>
                </el-col>
              </el-row>
              <el-row v-if="formatProjectStatusIs == 2">
                <el-col>
                  <EchartsIndex refId="formatProjectStatusPie" :option="formatProjectStatusPie"
                    styleStr="width: 100%;height: 270px;"
                    v-if="this.MG_EngineeringContractInfo.ECMP_Shop && this.MG_EngineeringContractInfo.ECMP_Shop.length > 0" />
                  <el-empty description="暂无数据" v-else style="width: 100%; height: 270px"></el-empty>

                </el-col>
              </el-row>
            </el-card>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <!-- 基础信息模块 MG_BaseInfoPage -->
    <el-row v-if="MG_BaseInfoPageFlag">
      <MGBaseInfoPage @changePageblock="Blick" v-loading="loading" :typeList="TypeList" :projectId="projectId"
        :homeData="MG_BaseInfo" />
    </el-row>
    <!-- 技术数据模块  "MG_TechnicalDataPage"-->
    <el-row v-if="MG_TechnicalDataPageFlag">
      <MGTechnicalDataPage @changePageblock="Blick" v-loading="loading" :typeList="TypeList" :projectId="projectId"
        :homeData="MG_TechnicalDataInfo">
      </MGTechnicalDataPage>
    </el-row>
    <!-- 餐饮数据模块  "MG_CateringDataPage", -->
    <div v-if="MG_CateringDataPageFlag">
      <MGCateringDataPage @changePageblock="Blick" v-loading="loading" :typeList="TypeList" :projectId="projectId"
        :homeData="MG_CateringDataInfo">
      </MGCateringDataPage>
    </div>
    <!-- 工程及合约信息模块 "MG_EngineeringContractPage", -->
    <div v-if="MG_EngineeringContractPageFlag">
      <MGEngineeringContractPage @changePageblock="Blick" v-loading="loading" :typeList="TypeList"
        :projectId="projectId" :homeData="MG_EngineeringContractInfo">
      </MGEngineeringContractPage>
    </div>
    <!-- 商铺变更原因数据 MG_ShopChangesDataPage-->
    <div v-if="MG_ShopChangesDataPageFlag">
      <MGShopChangesDataPage @changePageblock="Blick" v-loading="loading" :typeList="TypeList" :projectId="projectId"
        :homeData="MG_ShopChangesDataInfo">
      </MGShopChangesDataPage>
    </div>
    <!--综合数据查询模块 MG_ComprehensiveDataPage-->
    <div v-if="MG_ComprehensiveDataPageFlag">
      <MGComprehensiveDataPage @changePageblock="Blick" v-loading="loading" :typeList="TypeList" :projectId="projectId">
      </MGComprehensiveDataPage>
    </div>
    <div v-if="MG_ElectricityPageFlag">
      <MGElectricityPage @changePageblock="Blick" v-loading="loading" :typeList="TypeList" :projectId="projectId"
        :homeData="MG_CateringDataInfo">
      </MGElectricityPage>
    </div>

  </div>
</template>

<script>
// 引入echarts图表组件
import EchartsIndex from "@/components/Echarts/index";
import MGBaseInfoPage from "./MG_BaseInfoPage.vue";
import MGTechnicalDataPage from "./MG_TechnicalDataPage.vue";
import MGCateringDataPage from "./MG_CateringDataPage.vue";
import MGEngineeringContractPage from "./MG_EngineeringContractPage.vue";
import MGShopChangesDataPage from "./MG_ShopChangesDataPage.vue";
import MGComprehensiveDataPage from "./MG_ComprehensiveDataPage.vue";
import MGElectricityPage from "./MG_ElectricityPage.vue";

export default {
  name: "MG_HomePage", //招商首页数据
  components: {
    EchartsIndex,
    MGBaseInfoPage,
    MGTechnicalDataPage,
    MGCateringDataPage,
    MGEngineeringContractPage,
    MGShopChangesDataPage,
    MGComprehensiveDataPage,
    MGElectricityPage,
  },
  props: {
    typeList: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      TypeList: [],
      loading: false,
      Typeloading: false,
      formatCountOptionsRadioIs: 1,
      formatCountOptionsRadio: 1,
      shopChangedIs: 1,
      formatProjectStatusIs: 1,
      HomeFlag: true,
      // 各个板块data信息
      MG_BaseInfo: {},
      MG_TechnicalDataInfo: {},
      MG_CateringDataInfo: {},
      MG_EngineeringContractInfo: {},
      MG_ShopChangesDataInfo: {},
      //用电统计餐饮
      MG_ElectricityPageInfo: {},
      //各个板块显示状态
      MG_TechnicalDataPageFlag: false,
      MG_CateringDataPageFlag: false,
      MG_EngineeringContractPageFlag: false,
      MG_ShopChangesDataPageFlag: false,
      MG_BaseInfoPageFlag: false,
      MG_ComprehensiveDataPageFlag: false,
      //用电统计餐饮
      MG_ElectricityPageFlag: false,
      // 各个板块加载状态
      MG_BaseInfoLoading: false,
      MG_TechnicalDataInfoLoading: false,
      MG_CateringDataInfoLoading: false,
      MG_EngineeringContractInfoLoading: false,
      MG_ShopChangesDataInfoLoading: false,
      //用电统计餐饮
      MG_ElectricityPageInfoLoading: false,
      // 得铺率数据
      coverageRateOptions: {
        title: {
          text: "总计得铺率",
        },
        color: ["#E5E5E5", "#68BC00"], //圆环两色
        series: [
          {
            type: "pie",
            radius: ["60%", "80%"], //控制圆环大小
            center: ["50%", "50%"], //控制圆环位置
            data: [
              {
                value: 0,
                name: "",
                itemStyle: {
                  color: "#68BC00",
                },
                label: {},
              },
              {
                value: 0,
                name: "",
                itemStyle: {
                  color: "#E5E5E5",
                },
              },
            ],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                borderWidth: 5,
              },
            },
            label: {
              show: false,
              fontSize: "16",
              fontWeight: "bold",
              formatter: "\n{b}\n\n259\n\n得铺率\n\n{d}%",
              position: "center",
            },
          },
        ],
      },
      // 餐饮数量数据
      canyinCountOptions: {
        color: ["#E5E5E5", "#68BC00"], //圆环两色
        series: [
          {
            type: "pie",
            radius: ["70%", "90%"], //控制圆环大小
            center: ["50%", "50%"], //控制圆环位置
            data: [
              {
                value: 0,
                name: "餐饮商铺数量",
                itemStyle: {
                  color: "#68BC00",
                },
              },
              {
                value: 0,
                name: "",
                itemStyle: {
                  color: "#E5E5E5",
                },
              },
            ],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                borderWidth: 5,
              },
            },
            label: {
              show: false,
              fontSize: "16",
              fontWeight: "bold",
              formatter: "\n{b}\n\n259\n\n占比指数\n\n{d}%",
              position: "center",
            },
          },
        ],
      },
      // 餐饮面积数据
      canyinAreaOptions: {
        color: ["#E5E5E5", "#68BC00"], //圆环两色
        series: [
          {
            type: "pie",
            radius: ["70%", "90%"], //控制圆环大小
            center: ["50%", "50%"], //控制圆环位置
            data: [
              {
                value: 0,
                name: "餐饮商铺面积",
                itemStyle: {
                  color: "#68BC00",
                },
              },
              {
                value: 0,
                name: "",
                itemStyle: {
                  color: "#E5E5E5",
                },
              },
            ],
            hoverAnimation: false,
            itemStyle: {
              normal: {
                borderWidth: 5,
              },
            },
            label: {
              show: false,
              fontSize: "16",
              fontWeight: "bold",
              formatter: "\n{b}\n\n259\n\n占比指数\n\n{d}%",
              position: "center",
            },
          },
        ],
      },

      // 业态数量占比数据
      formatCountOptions: {
        tooltip: {},

        label: {
        },
        series: [
          {
            type: 'sunburst',
            center: ['50%', '50%'],
            // radius: [0, '80%'],
            data: [],

            label: {
              rotate: 'radial',

            },
            clockwise: true,
            itemStyle: {
              borderRadius: 8,
              borderWidth: 2
            },
            levels: [
              {
                // rotate: 0,
                radius: [0, '10%'],
                label: {
                  rotate: 0,
                  // formatter: (a) => {
                  //   return a.name +
                  //     '\n\n' + a.value.toFixed(2) + '㎡'
                  // },
                },
              },
              {

                radius: ['25%', '50%'],
                label: {
                  rotate: 0,
                },
                tooltip: {
                  //tip提示框
                  trigger: "item",
                  // formatter: "{a} <br/>{b} : {c} ",
                  formatter: (a) => {
                    console.log(a, 'aaaaaa')
                    // console.log(a.value / a.treePathInfo[0].value * 100)
                    let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
                    return a.name + " : " + num + "%"
                  }

                },
              },
              {
                radius: ['55%', '80%'],
                label: {
                  rotate: 0,
                  position: 'outside'
                }
              },

            ]
          }
        ]

      },

      // 商铺变更原因柱状图数据
      shopChangedBar: {
        legend: {

          data: [],
          top: 0,
        },
        grid: {
          top: 60,
          bottom: "4%",
        },

        tooltip: {
          trigger: "item",
        },
        xAxis: {
          // show: false,
          type: "category",
          data: [],
        },
        yAxis: {
          name: "变更次数",
          nameLocation: "end",
          nameTextStyle: {
            width: 200,
            align: "right",
          },
          axisLabel: {
            formatter: "{value} 次",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          show: true,
          type: "value",
        },
        label: {

        },
        series: [],
      },
      // 商铺变更原因饼图数据
      shopChangedPie: {
        legend: {

          data: [],
          top: 10,
        },

        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        label: {

        },
        yAxis: {
          show: false,
        },
        xAxis: {
          show: false,
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "65%"],
            selectedMode: "single",

            data: [

            ],


          },
        ],
      },
      //工程及合约信息柱状图
      formatProjectStatusBar: {
        grid: {
          top: 60,
          bottom: "4%",
        },
        tooltip: {
          trigger: "item",
        },
        label: {

        },
        legend: {
          data: [],
          top: 0,
        },
        xAxis: {
          show: true,
          type: "category",
          data: [],
        },
        yAxis: {
          name: "商铺数量",
          nameLocation: "end",
          nameTextStyle: {
            width: 200,
            align: "right",
          },
          axisLine: {
            //坐标轴轴线
            show: true,
          },
          axisTick: {
            //坐标轴刻度
            show: true,
          },
          show: true,
          type: "value",
        },
        series: [

        ],
      },
      //工程及合约信息饼图
      formatProjectStatusPie: {
        legend: {

          data: [],
          top: 10,
        },
        label: {

        },
        tooltip: {
          trigger: "item",
          formatter: "{b} : {c} ({d}%)",
        },
        yAxis: {
          show: false,
        },
        xAxis: {
          show: false,
        },
        series: [
          {
            type: "pie",
            radius: "65%",
            center: ["50%", "65%"],
            selectedMode: "single",
            data: [

            ],

          },
        ],
      },

      formatCountInfoArr: []
    };
  },
  async created() {
    await this.getTypeList();
    this.getMG_BaseInfoData();
    this.getMG_TechnicalDataInfo();
    this.getMG_CateringDataInfo();
    this.getMG_ShopChangesDataInfo()
    this.getMG_EngineeringContractInfo()
  },
  computed: {
    formatCountOptionsNumView2() {
      let arr = this.formatCountInfoArr
      if (arr.length == 1) {
        return `<span>${arr[0].name}</span>：<span style="color:#D1100B">${arr[0].value || 0}</span>`
      }
      if (arr.length == 2) {
        let num = (arr[1].value / arr[0].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}：<span style="color:#D1100B">${arr[1].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span>`
      }
      if (arr.length == 3) {
        let num = (arr[2].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[2].value / arr[1].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}：<span style="color:#D1100B">${arr[2].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span>`
      }
      if (arr.length == 4) {
        let num = (arr[3].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[3].value / arr[1].value * 100).toFixed(2)
        let num3 = (arr[3].value / arr[2].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value || 0}</span></br>${arr[1].name}>${arr[2].name}>${arr[3].name}：<span style="color:#D1100B">${arr[3].value}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span><br/>${arr[2].name}占比：<span style="color:#D1100B">${num3}%</span>`
      }
      return ''
    },
    formatCountOptionsNumView() {
      let arr = this.formatCountInfoArr
      if (arr.length == 1) {
        return `<span>${arr[0].name}</span>：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span>`
      }
      if (arr.length == 2) {
        let num = (arr[1].value / arr[0].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span></br>${arr[1].name}：<span style="color:#D1100B">${arr[1].value?.toFixed(2) + '㎡' || '0㎡'}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span>`
      }
      if (arr.length == 3) {
        let num = (arr[2].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[2].value / arr[1].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span></br>${arr[1].name}>${arr[2].name}：<span style="color:#D1100B">${arr[2].value?.toFixed(2) + '㎡' || '0㎡'}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span>`
      }
      if (arr.length == 4) {
        let num = (arr[3].value / arr[0].value * 100).toFixed(2)
        let num2 = (arr[3].value / arr[1].value * 100).toFixed(2)
        let num3 = (arr[3].value / arr[2].value * 100).toFixed(2)
        return `${arr[0].name}：<span style="color:#D1100B">${arr[0].value?.toFixed(2) + '㎡' || '0㎡'}</span></br>${arr[1].name}>${arr[2].name}>${arr[3].name}：<span style="color:#D1100B">${arr[3].value?.toFixed(2) + '㎡' || '0㎡'}</span><br/>${arr[0].name}占比：<span style="color:#D1100B">${num}%</span><br/>${arr[1].name}占比：<span style="color:#D1100B">${num2}%</span><br/>${arr[2].name}占比：<span style="color:#D1100B">${num3}%</span>`
      }
      return ''
    },
  },
  mounted() { },
  methods: {
    formatCountOptionsClick(a) {
      if (this.formatCountOptionsRadioIs === 1) {
        if (a.name === '商铺总面积') {
          this.formatCountInfoArr.pop()
        } else {
          this.formatCountInfoArr = [...a.treePathInfo]
        }
      } else if (this.formatCountOptionsRadioIs === 2) {

        if (a.name === '商铺总数量') {
          this.formatCountInfoArr.pop()
        } else {
          this.formatCountInfoArr = [...a.treePathInfo]
        }
      }

    },
    getMG_EngineeringContractInfo() {
      this.MG_EngineeringContractInfoLoading = true
      this.$http.post(
        "/Base_ProjectManage/Project_DataAnalysis/GetHomePageECModuleInfo?projectId=" +
        this.projectId
      )
        .then((res) => {
          this.MG_EngineeringContractInfoLoading = false
          if (res.Success && res.Data) {
            this.MG_EngineeringContractInfo = res.Data || {};
            this.formatProjectStatusBar.legend.data = this.MG_EngineeringContractInfo.ECMP_Shop
            this.formatProjectStatusBar.label = {
              show: true,
              position: "top",
              formatter: `{c}`
            }
            this.MG_EngineeringContractInfo.ECMP_Shop.forEach(item => {
              this.formatProjectStatusBar.series.push({
                name: item.name,
                data: [item.value],
                type: "bar",
                emphasis: {
                  focus: "series",
                },
                barGap: "100%",
                showBackground: true,
                backgroundStyle: {
                  color: "rgba(180, 180, 180, 0.2)",
                }
              })
            })

            this.formatProjectStatusPie.legend.data = this.MG_EngineeringContractInfo.ECMP_Shop || []
            this.formatProjectStatusPie.label = {
              show: true,

              formatter: "{b} : {c} ({d}%)",
            }
            this.formatProjectStatusPie.series[0].data = this.MG_EngineeringContractInfo.ECMP_Shop || []
          } else {
            this.MG_EngineeringContractInfo = {};
            this.$message.error("工程及合约信息模块暂无数据");
          }
        }).catch(() => {
          this.MG_EngineeringContractInfoLoading = false
          this.MG_EngineeringContractInfo = {};
          this.$message.error("工程及合约信息模块暂无数据");
        })
    },
    getMG_ShopChangesDataInfo() {
      this.MG_ShopChangesDataInfoLoading = true
      this.$http
        .post(
          "/Base_ProjectManage/Project_DataAnalysis/GetHomePageShopChangeReasonInfoData?projectId=" +
          this.projectId
        )
        .then((res) => {
          this.MG_ShopChangesDataInfoLoading = false
          if (res.Success && res.Data) {
            this.MG_ShopChangesDataInfo = res.Data || {};
            this.shopChangedBar.legend.data = this.MG_ShopChangesDataInfo.SCRP_Shop
            this.shopChangedBar.label = {
              show: true,
              position: "top",
              formatter: `{c}次`
            }

            this.MG_ShopChangesDataInfo.SCRP_Shop.forEach(item => {
              this.shopChangedBar.series.push({
                name: item.name,
                data: [item.value],
                type: "bar",
                emphasis: {
                  focus: "series",
                },
                barGap: "100%",
                showBackground: true,
                backgroundStyle: {
                  color: "rgba(180, 180, 180, 0.2)",
                }
              })
            })

            this.shopChangedPie.legend.data = this.MG_ShopChangesDataInfo.SCRP_Shop || []
            this.shopChangedPie.label = {
              show: true,

              formatter: "{b} : {c}次 ({d}%)",
            }
            this.shopChangedPie.series[0].data = this.MG_ShopChangesDataInfo.SCRP_Shop || []
          } else {
            this.MG_ShopChangesDataInfo = {};

            this.$message.error("商铺变动原因模块暂无数据");
          }
        }).catch(() => {
          this.MG_ShopChangesDataInfoLoading = false
          this.MG_ShopChangesDataInfo = {};
          this.$message.error("餐饮信息模块暂无数据");
        })
    },
    getMG_CateringDataInfo() {
      this.MG_CateringDataInfoLoading = true
      this.$http
        .post(
          "/Base_ProjectManage/Project_DataAnalysis/GetMG_HomePageCateringData?projectId=" +
          this.projectId
        )
        .then((res) => {
          this.MG_CateringDataInfoLoading = false
          if (res.Success && res.Data) {
            this.MG_CateringDataInfo = res.Data || {};
            this.canyinCountOptions.series[0].data[0].value = this.MG_CateringDataInfo.ShopCountProportion;
            this.canyinCountOptions.series[0].data[1].value = (100 - this.MG_CateringDataInfo.ShopCountProportion);
            this.canyinCountOptions.series[0].data[0].label = {
              show: true,
              fontSize: "16",
              fontWeight: "bold",
              formatter: `\n{b}\n\n${this.MG_CateringDataInfo.ShopCountCatering}\n\n占比指数\n\n{d}%`,
              position: "center",
              color: "rgb(150,150,150)",
            };
            this.canyinAreaOptions.series[0].data[0].value = this.MG_CateringDataInfo.ShopAreaProportion;
            this.canyinAreaOptions.series[0].data[1].value = (100 - this.MG_CateringDataInfo.ShopAreaProportion)
            this.canyinAreaOptions.series[0].data[0].label = {
              show: true,
              fontSize: "16",
              fontWeight: "bold",
              formatter: `\n{b}\n\n${this.MG_CateringDataInfo.ShopAreaCatering}㎡\n\n占比指数\n\n{d}%`,
              position: "center",
              color: "rgb(150,150,150)",
            };
          } else {
            this.MG_CateringDataInfo = {};

            this.$message.error("餐饮信息模块暂无数据");
          }
        }).catch(() => {
          this.MG_CateringDataInfoLoading = false
          this.MG_CateringDataInfo = {};
          this.$message.error("餐饮信息模块暂无数据");
        })
    },
    getMG_TechnicalDataInfo() {
      this.MG_TechnicalDataInfoLoading = true
      this.$http
        .post(
          "/Base_ProjectManage/Project_DataAnalysis/GetMG_HomePageTechnicalData?projectId=" +
          this.projectId
        )
        .then((res) => {
          this.MG_TechnicalDataInfoLoading = false
          if (res.Success && res.Data) {
            this.MG_TechnicalDataInfo = res.Data || {};
          } else {
            this.MG_TechnicalDataInfo = {};
            this.$message.error("餐饮信息模块暂无数据");
          }
        }).catch(() => {
          this.MG_TechnicalDataInfoLoading = false
          this.MG_TechnicalDataInfo = {};
          this.$message.error("餐饮信息模块暂无数据");
        })
    },
    getMG_BaseInfoData() {
      this.MG_BaseInfoLoading = true
      this.$http
        .post(
          "/Base_ProjectManage/Project_DataAnalysis/GetMG_HomePageBaseInfoData?projectId=" +
          this.projectId
        )
        .then((res) => {
          this.MG_BaseInfoLoading = false
          if (res.Success && res.Data) {
            this.MG_BaseInfo = res.Data || {};
            this.coverageRateOptions.series[0].data[0].value = this.MG_BaseInfo.ShopCoverageRate;
            this.coverageRateOptions.series[0].data[1].value = (100 - this.MG_BaseInfo.ShopCoverageRate);
            this.coverageRateOptions.series[0].data[0].label = {
              show: true,
              fontSize: "16",
              fontWeight: "bold",
              formatter: `\n入住商家数量\n\n${this.MG_BaseInfo.ShopTotalNumber}\n\n得铺率\n\n${this.MG_BaseInfo.ShopCoverageRate}%`,
              position: "center",
              color: "#9b9999",
            };
            this.changeType();
          } else {
            this.MG_BaseInfo = {};
            this.$message.error("基础信息模块暂无数据");
          }
        }).catch(() => {
          this.MG_BaseInfoLoading = false
          this.MG_BaseInfo = {};
          this.$message.error("基础信息模块暂无数据");
        })
    },

    changeType() {
      if (this.formatCountOptionsRadioIs === 1) {
        let arr = []
        let colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
        console.log(this.MG_BaseInfo.FAP_Shop, '  this.MG_BaseInfo.FAP_Shop')
        this.MG_BaseInfo.FAP_Shop.forEach(item => {
          let num = 0
          item.shopAreaList.forEach((val, index) => {
            val.itemStyle = {
              color: colorList[index > colorList.length - 1 ? index - colorList.length - 1 : index]
            }
            num = val.value + num
          })

          let obj = {
            name: item.formatCalss,
            value: item.shopArea ? item.shopArea : num ? Number(num.toFixed(2)) : 0,
            children: [...item.shopAreaList]
          }
          arr.push(obj)
        })
        this.formatCountOptions.series[0].data = arr;
        this.formatCountOptions.series[0].name = '商铺总面积';
        this.formatCountOptions.series[0].levels[0].label = {
          rotate: 0,
          formatter: (a) => {
            return a.name +
              '\n\n' + a.value.toFixed(2) + '㎡'
          },
        }
        this.formatCountOptions.label = {
          show: true,
          rotate: 'radial',
          formatter: (a) => {
            return a.name +
              '\n' + a.value.toFixed(2) + '㎡'
          },

        }
        this.formatCountOptions.tooltip = {
          //tip提示框
          trigger: "item",

          formatter: (a) => {

            if (a.treePathInfo.length == 1) {
              return `${a.name}：${a.value ? a.value.toFixed(2) : 0}㎡`
            }

            if (a.treePathInfo.length == 2) {
              let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
              return `${a.name}：${a.value + '㎡'}<br/>${a.treePathInfo[0].name}占比：${num}%`
            }
            if (a.treePathInfo.length == 3) {
              let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
              let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
              return `${a.name}：${a.value + '㎡'}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
            }

          }

        }
        let num = 0
        this.MG_BaseInfo.FAP_Shop.forEach(item => {
          num = num + item.shopArea
        })
        this.formatCountInfoArr = [{
          name: '商铺总面积',
          value: num
        }]
      } else if (this.formatCountOptionsRadioIs === 2) {
        let arr = []
        let colorList = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc']
        this.MG_BaseInfo.FAP_Shop.forEach(item => {
          let num = 0
          item.shopCountList.forEach((val, index) => {
            val.itemStyle = {
              color: colorList[index > colorList.length - 1 ? index - colorList.length - 1 : index]
            }
            num = val.value + num
          })
          let obj = {
            name: item.formatCalss,
            value: item.shopCount ? item.shopCount : num ? Number(num.toFixed(2)) : 0,
            children: [...item.shopCountList]
          }
          arr.push(obj)
        })
        this.formatCountOptions.series[0].name = '商铺总数量';
        this.formatCountOptions.series[0].data = arr;
        this.formatCountOptions.label = {
          show: true,
          rotate: 'radial',
          formatter: `{b}\n{c}`
        }
        this.formatCountOptions.series[0].levels[0].label = {
          rotate: 0,
          formatter: (a) => {
            return a.name +
              '\n\n' + a.value
          },
        }
        this.formatCountOptions.tooltip = {
          //tip提示框
          trigger: "item",

          formatter: (a) => {

            if (a.treePathInfo.length == 1) {
              return `${a.name}：${a.value ? a.value.toFixed(2) : 0}`
            }
            if (a.treePathInfo.length == 2) {
              let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
              return `${a.name}:${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%`
            }
            if (a.treePathInfo.length == 3) {
              let num = (a.value / a.treePathInfo[0].value * 100).toFixed(2)
              let num2 = (a.value / a.treePathInfo[1].value * 100).toFixed(2)
              return `${a.name}：${a.value}<br/>${a.treePathInfo[0].name}占比：${num}%<br/>${a.treePathInfo[1].name}占比：${num2}%`
            }

          }

        }
        let num = 0
        this.MG_BaseInfo.FAP_Shop.forEach(item => {
          num = num + item.shopCount
        })
        this.formatCountInfoArr = [{
          name: '商铺总数量',
          value: num
        }]
      }
    },
    async getTypeList() {
      this.Typeloading = true
      await this.$http
        .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
          Condition: "DicKey",
          Keyword: "FormatClass",
        })
        .then((res) => {
          this.Typeloading = false
          if (res.Success) {
            this.TypeList = res.Data.DictionaryDetailsList || [];
            this.formatCountOptionsRadio = res.Data.DictionaryDetailsList[0].DicName;
          } else {
            this.TypeList = [];
          }
        });
    },
    MG_TechnicalPageOpen() {
      this.loading = true;
      this.HomeFlag = false;
      this.MG_TechnicalPageFlag = true;
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    Blick() {
      this.loading = true;
      //信息模块
      this.MG_TechnicalDataPageFlag = false;
      // 餐饮数据模块
      this.MG_CateringDataPageFlag = false;
      // 工程及合约信息模块
      this.MG_EngineeringContractPageFlag = false;
      // 商铺变更原因数据
      this.MG_ShopChangesDataPageFlag = false;
      //基础信息模块
      this.MG_BaseInfoPageFlag = false;
      //综合查询信息模块
      this.MG_ComprehensiveDataPageFlag = false;
      //用电统计
      this.MG_ElectricityPageFlag = false;
      this.HomeFlag = true;
      this.$nextTick(() => {
        this.loading = false;
      });
    },
    PageOpen(str) {
      this.loading = true;
      this.HomeFlag = false;
      this.MG_BaseInfoPageFlag = false;
      this.MG_TechnicalDataPageFlag = false;
      this.MG_CateringDataPageFlag = false;
      this.MG_EngineeringContractPageFlag = false;
      this.MG_ShopChangesDataPageFlag = false;
      //用电统计
      this.MG_ElectricityPageFlag = false;
      if (str === "MG_TechnicalDataPage") {
        this.MG_TechnicalDataPageFlag = true;
      } else if (str === "MG_CateringDataPage") {
        this.MG_CateringDataPageFlag = true;
      } else if (str === "MG_EngineeringContractPage") {
        this.MG_EngineeringContractPageFlag = true;
      } else if (str === "MG_ShopChangesDataPage") {
        this.MG_ShopChangesDataPageFlag = true;
      } else if (str === "MG_BaseInfoPage") {
        this.MG_BaseInfoPageFlag = true;
      } else if (str === "MG_ComprehensiveDataPage") {
        this.MG_ComprehensiveDataPageFlag = true;
      } else if (str === "MG_ElectricityPage") {
        this.MG_ElectricityPageFlag = true;
      }
      this.$nextTick(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.box {
  // padding: 5px;
  padding: 5px 0px;
  box-sizing: border-box;

  ::v-deep .el-card__header {
    padding: 0;
    height: 50px;
    text-align: left;
    text-indent: 20px;
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
    box-sizing: border-box;
  }

  // 外层容器内填充
  ::v-deep .el-card__body {
    padding: 10px 10px 0;
  }

  //综合基础查询界面
  .ComprehensiveDataCard {
    // min-height: 50px;
    height: 50px;
    line-height: 40px;
    border-radius: 10px;
    border: 5px solid #c3c3c3;
    margin-bottom: 10px;
    box-sizing: border-box;

    .title {
      display: inline-block;
      font-size: 20px;
      font-weight: bold;
      margin-left: 20px;
    }
  }

  // 基础信息Card
  .baseInfoCard {
    border-radius: 10px;
    height: 710px;
    box-sizing: border-box;
    border: 5px solid #c3c3c3;
    // padding-bottom: 10px;

    //左侧3个数据条
    .dataStripe {
      width: 100%;
      height: 85px;
      border-radius: 20px;
      margin-bottom: 10px;

      // 设定卡片内填充
      ::v-deep .el-card__body {
        padding: 5px 0;
      }

      // 数据条卡片内图标
      .dataStripe_i {
        font-size: 40px;
        color: #ffffff;
        line-height: 80px;
        margin-left: 20px;
      }

      // 数据条卡片内标题
      .dataStripe_title {
        display: block;
        line-height: 36px;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }

      // 数据条卡片内显示值
      .dataStripe_value {
        display: block;
        line-height: 36px;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }
    }

    //得普率占比图卡片
    .coverageRateCard {
      padding: 10px;
      width: 92%;
      height: 260px;
      border-radius: 15px;

      // 设定卡片内填充
      ::v-deep .el-card__body {
        padding: 0;
        margin: 0;
      }
    }
  }

  // 技术信息模块
  .technicalModule {
    border-radius: 10px;
    height: 405px;

    border: 5px solid #c3c3c3;
    box-sizing: border-box;

    ::v-deep .el-card__body {
      padding: 10px 10px 0;
    }

    .dataStripe {
      width: 100%;
      height: 100px;
      border-radius: 20px;
      margin-bottom: 10px;

      // 设定卡片内填充
      ::v-deep .el-card__body {
        padding: 5px 0;
      }

      // 数据条卡片内图标
      .dataStripe_i {
        font-size: 40px;
        color: #ffffff;
        line-height: 90px;
        margin-left: 20px;
      }

      // 数据条卡片内标题
      .dataStripe_title {
        display: block;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }

      // 数据条卡片内显示值
      .dataStripe_value {
        display: block;
        line-height: 50px;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }

  // 餐饮信息模块
  .cateringModule {
    border-radius: 10px;
    border: 5px solid #c3c3c3;
    height: 405px;
    box-sizing: border-box;

    ::v-deep .el-card__body {
      padding: 10px 10px 0;
    }

    .dataStripe {
      width: 100%;
      height: 100px;
      border-radius: 20px;
      margin-bottom: 10px;

      // 设定卡片内填充
      ::v-deep .el-card__body {
        padding: 5px 0;
      }

      // 数据条卡片内图标
      .dataStripe_i {
        font-size: 40px;
        color: #ffffff;
        line-height: 90px;
        margin-left: 20px;
      }

      // 数据条卡片内标题
      .dataStripe_title {
        display: block;
        line-height: 40px;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }

      // 数据条卡片内显示值
      .dataStripe_value {
        display: block;
        line-height: 50px;
        font-size: 20px;
        font-weight: bold;
        color: #ffffff;
      }
    }
  }

  // 商铺变更原因模块
  .storeChangeModule {
    height: 355px;
    border-radius: 10px;
    border: 5px solid #c3c3c3;
    box-sizing: border-box;

    ::v-deep .el-card__body {
      padding: 10px 10px 0;
    }
  }

  // 工程以及合约信息模块
  .ecModule {
    height: 355px;
    border-radius: 10px;
    border: 5px solid #c3c3c3;
    box-sizing: border-box;

    ::v-deep .el-card__body {
      padding: 10px 10px 0;
    }
  }
}



::v-deep button:focus {
  outline: none;
}

// ::v-deep .el-button:focus {
//   color: var(--el-button-text-color);
//   background-color: var(--el-button-bg-color);
//   border-color: var(--el-button-border-color);
// }

::v-deep .el-checkbox-button.is-focus .el-checkbox-button__inner {
  border-color: #DCDFE6;
}

::v-deep .el-checkbox-button.is-checked .el-checkbox-button__inner {
  border-color: #D1100B;
}
</style>
