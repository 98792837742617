<template>
    <!-- 中间tab -->
    <div class="list">
        <div class="listItem" v-for="(item, index) in List" :key="index">
            <div class="itemTitle">
                <span>{{ item.title }}</span>
            </div>
            <div class="addBox" v-if="item.title == '启动任务' && taskObj.ApplyCode != '0'">
                <el-button size="mini" v-if="!item.showBox" class="addBtn" icon="el-icon-plus"
                    @click="showContent(index)"></el-button>

                <div v-else class="addContent">
                    <el-select v-model="item.TaskTypeKey" placeholder="请选择任务类型" size="small"
                        v-if="TaskTypeList.length > 0">
                        <el-option v-for="val in TaskTypeList" :key="val.DicValue" :label="val.DicName"
                            :value="val.DicValue"></el-option>
                    </el-select>
                    <el-input type="textarea" :rows="3" placeholder="请输入标题新建任务" v-model="item.textarea"
                        style="font-family: Arial;margin-top: 10px;" maxlength="100" show-word-limit>
                    </el-input>

                    <div class="footer">
                        <el-button size="mini" @click="List[index].showBox = false">取消</el-button>
                        <el-button size="mini" :disabled="!item.textarea" type="primary"
                            @click="confirmItem(index)">确定</el-button>
                    </div>
                </div>
            </div>
            <!-- 子表 -->
            <div class="subList">
                <div class="subItem" v-for="(im, ix) in item.Sub" :key="ix" @click="openForm(im, index)">
                    <span class="tag"></span>
                    <div class="header">
                        <div class="h2" v-if="!im.show">{{ im.Name }}</div>
                        <div class="h2" v-else @click.stop="() => { }">
                            <el-input style="width: 90%" v-model="im.oldName"
                                @keydown.native="taskNameChange($event, im, index)"></el-input>
                        </div>
                        <div type="danger" style="
                  margin: -14px 5px 6px 0;
                  font-size: 12px;
                  color: #409EFF;
                  border: 1px solid #409EFF;
                  padding: 1px 5px;
                  border-radius: 3px;
                " v-if="im.TaskTypeName">
                            {{ im.TaskTypeName }}
                        </div>
                        <div type="danger" style="
                  margin: -14px -52px 6px 0;
                  font-size: 12px;
                  color: #d1100b;
                  border: 1px solid #d1100b;
                  padding: 1px 5px;
                  border-radius: 3px;
                ">
                            {{ im.AppyName }}
                        </div>

                        <div class="timeTag">
                            <el-dropdown trigger="click" @command="handleCommand($event, index, im)">
                                <span class="el-dropdown-link" @click.stop="() => { }">
                                    <i class="el-icon-more"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-edit"
                                        :command="{ im, type: 'edit', ix, index }">编辑</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-delete"
                                        :command="{ im, type: 'del', ix, index }">删除</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                            <div class="time"
                                style="margin-top: 6px;width: 150px; display: flex; justify-content: flex-end">
                                {{ im.ModifyDate ? im.ModifyDate.substring(0, 19) : "" }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog title="编辑任务" :visible.sync="dialogVisible" width="30%" :close-on-click-modal="false">
            <el-form ref="editTaskForm" :model="editInfo" :rules="rules" label-width="100px" v-loading="editLoading">
                <el-form-item label="任务类型" prop="TaskTypeKey" v-if="EditTaskTypeList.length > 0">
                    <el-select v-model="editInfo.TaskTypeKey" placeholder="请选择任务类型" :disabled="EditTaskTypeDisabled">
                        <el-option v-for="item in EditTaskTypeList" :key="item.DicValue" :label="item.DicName"
                            :value="item.DicValue"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="任务名称" placeholder="请输入任务名称" prop="Name">
                    <el-input v-model="editInfo.Name" maxlength="100" show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="editSubItem" v-loading="editLoading">确 定</el-button>
            </span>
        </el-dialog>
        <!-- <RGList ref="RGList" /> -->
    </div>
</template>

<script>


export default {
    components: {

    },
    props: {
        taskObj: { type: Object, require: true },
        ClassType: { type: Number, require: true },
    },
    data() {
        return {
            rules: {
                Name: [{ required: true, message: "请输入任务名称", trigger: "blur" }],
                TaskTypeKey: [{ required: true, message: "请选择任务类型", trigger: "change" }],
            },
            dialogVisible: false,

            showInput: false,
            // 巡检记录
            RecordingList: [],
            // 主表
            List: [
                { title: "启动任务", Sub: [] },
                { title: "进行中", Sub: [] },
                { title: "已完成", Sub: [] },
            ],
            imgList: [],
            TaskTypeList: [],
            //编辑任务
            EditTaskTypeDisabled: true,
            EditTaskTypeList: [],
            editInfo: {},
            editIndex: 0,
            editLoading: false,
        };
    },
    created() {

    },
    methods: {
        editSubItem() {
            this.$refs.editTaskForm.validate((valid) => {
                if (!valid) return;
                this.editLoading = true
                this.$http
                    .post("/Base_TaskWork/Base_Task/SaveData", {
                        ...this.editInfo,
                    })
                    .then((res) => {
                        this.editLoading = false
                        if (res.Success) {
                            this.getDataList(this.editIndex);
                            this.dialogVisible = false;
                            this.$message.success("操作成功");
                        } else {
                            this.$message.error(res.Msg);
                        }
                    });
            })

        },
        getTaskTypeList(code, isEdit) {
            this.$http
                .post("/Base_ProjectManage/Project_DictionaryMain/GetTheDataByDicKey", {
                    Condition: "DicKey",
                    Keyword: 'TaskType_' + code,
                }).then(res => {
                    if (res.Success) {
                        if (isEdit) {
                            this.EditTaskTypeList = res.Data?.DictionaryDetailsList || []
                            return
                        }
                        this.TaskTypeList = res.Data?.DictionaryDetailsList || []
                    }
                })
        },
        // 任务名称变更
        taskNameChange(e, im, index) {
            if (e.keyCode == 13) {
                im.Name = im.oldName;
                this.$http.post("/Base_TaskWork/Base_Task/SaveData", im).then((res) => {
                    if (res.Success) {
                        this.getDataList(index);
                        this.$message.success("操作成功");
                    } else this.$message.error(res.Msg);
                });
            } else if (e.keyCode == 27) {
                this.$set(im, "show", false);
            }
        },
        // 编辑删除
        handleCommand(e, index, im) {
            if (e.type == "edit") {

                this.dialogVisible = true
                console.log(im)
                this.EditTaskTypeDisabled = im.Status > 0 ? true : false

                this.editIndex = index
                this.getTaskTypeList(im.ApplyCode, 'edit')
                this.$nextTick(() => {
                    if (this.$refs.editTaskForm) this.$refs.editTaskForm.clearValidate();
                    this.editInfo = { ...im }
                })
                // // this.$set(e.im, "show", true);
                // // this.$set(e.im, "oldName", e.im.Name);
            } else {
                this.$confirm("此操作将永久删除该任务, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        this.$http
                            .post("Base_TaskWork/Base_Task/TaskDeleteData", [e.im.Id])
                            .then((res) => {
                                if (res.Success) {
                                    this.getDataList(e.index);
                                    this.$message.success("操作成功");
                                } else this.$message.error(res.Msg);
                            });
                    })
                    .catch(() => { });
            }
        },
        openForm(item, index) {
            this.$emit("task", { ...item, status: false, index });
            // console.log(item, index)
            // this.$refs.RGList.openForm(item, index);
            // if (index == 0) return;
            // console.log(item, index);
            // this.$refs.patGoForm.openForm(item);
        },
        // 开启新建标题
        showContent(index) {
            this.$set(this.List[index], "showBox", true);
        },
        // 各列保存
        confirmItem(index) {
            console.log(index)
            console.log(this.List)
            console.log(this.List[index])
            console.log(this.taskObj)

            this.$http
                .post("/Base_TaskWork/Base_Task/SaveData", {
                    ...this.taskObj,
                    Status: this.List[index].Status || 0,
                    TaskTypeKey: this.List[index].TaskTypeKey || '',
                    Name: this.List[index].textarea,
                })
                .then((res) => {
                    if (res.Success) {
                        this.getDataList(index);
                        this.List[index].textarea = "";
                        this.List[index].TaskTypeKey = "";

                        this.$set(this.List[index], "showBox", false);
                        this.$message.success("操作成功");
                    } else {
                        this.$message.error(res.Msg);
                    }
                });
        },
        sortCommand(e) {
            console.log(e);
        },
        getDataList(index) {
            this.$http
                .post("/Base_TaskWork/Base_Task/GetDataListByMoreCondition", {
                    Search: {
                        ClassType: this.ClassType,
                        Conditions: [
                            {
                                Condition: "ProjectId",
                                Keyword: this.taskObj.ProjectId,
                            },
                            {
                                Condition: "AppyId",
                                Keyword: this.taskObj.AppyId,
                            },
                            {
                                Condition: "Status",
                                Keyword: index,
                            },
                        ],
                    },
                })
                .then((res) => {
                    if (res.Success) {
                        this.List[index].Sub = res.Data;
                        // 任务进入且应用ID为 1620239917378441218 (巡检GO)
                        // if (
                        //     this.$route.query.ApplyCode == "10005"
                        // ) {

                        //     // 证明开启过不再开启
                        //     if (sessionStorage.getItem("ElectricalGOOpen")) return;
                        //     let a = this.List[index].Sub.find(
                        //         (item) => item.Id == this.$route.query.TaskId
                        //     );
                        //     if (!a) return;
                        //     this.openForm(a, index);
                        //     // 找到任务后开启状态
                        //     sessionStorage.setItem("ElectricalGOOpen", "1");
                        // }
                    } else {
                        this.$message.error(res.Msg);
                    }
                });
        },
    },
    computed: {

    },
    watch: {
        // "taskObj.ProjectId": {
        //     handler(n, o) {
        //         console.log(n, '111111111')
        //         if (!n) return;
        //         for (let i = 0; i < 3; i++) {
        //             this.getDataList(i);
        //         }
        //     },
        // },
        taskObj: {
            immediate: true,
            deep: true,
            handler(n, o) {
                console.log(n, 'taskObj')
                if (n.ProjectId) {
                    this.List = [
                        { title: "启动任务", Sub: [] },
                        { title: "进行中", Sub: [] },
                        { title: "已完成", Sub: [] },
                    ]
                    for (let i = 0; i < 3; i++) {
                        this.getDataList(i);
                    }
                    this.getTaskTypeList(this.taskObj.ApplyCode)
                }

            }
        },
        // ClassType: {
        //     handler(n, o) {
        //         console.log(n, '2222222')
        //         if (n >= 0 && this.taskObj.ProjectId) {
        //             for (let i = 0; i < 3; i++) {
        //                 this.getDataList(i);
        //             }
        //         }
        //     },
        //     immediate: true,
        // },
    },
};
</script>

<style lang="scss" scoped>
.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.list {
    display: flex;

    .listItem {
        margin-right: 24px;
        font-size: 14px;
        flex: 1;
        border-right: 1px solid #cccccc;
        padding-right: 20px;
        max-height: calc(100vh - 150px);
        overflow-y: auto;

        .itemTitle {
            @extend .flex;
            font-weight: bold;
            height: 40px;
        }

        .addBox {
            margin-bottom: 8px;

            .addBtn {
                width: 100%;
                font-weight: bold;
                font-size: 20px;
            }

            .addContent {
                border-radius: 3px;
                box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
                box-sizing: border-box;
                padding: 12px;
                background: #fff;

                .footer {
                    margin-top: 14px;
                    padding-top: 12px;
                    text-align: right;
                    border-top: 1px solid #ccc;
                }
            }
        }

        .subList {
            .subItem {
                overflow: hidden;
                position: relative;
                padding: 4px 12px;
                margin-bottom: 8px;
                background-color: #fff;
                box-shadow: 0 1px 5px rgb(0 0 0 / 10%);
                border-radius: 3px;
                box-sizing: border-box;

                &:hover {
                    .tag {
                        width: 3px;
                    }
                }

                cursor: pointer;

                .tag {
                    position: absolute;
                    height: 100%;
                    width: 0px;
                    left: 0;
                    top: 0;
                    background: #d10d08;
                    transition: all 70ms ease 0s;
                }

                .header {
                    display: flex;
                    align-items: center;
                    height: 100%;

                    .h2 {
                        margin: 0;
                        flex: 1;
                        font-weight: normal;
                        font-size: 13px;
                    }

                    .timeTag {
                        display: flex;
                        flex-direction: column;
                        width: 75px;
                        align-items: flex-end;

                        .time {
                            font-size: 12px;
                            color: gray;
                        }
                    }
                }

                .footer {
                    margin-top: 6px;
                }
            }
        }
    }
}
</style>