<template>
  <div style="box-sizing: border-box;position: relative" body-style="padding: 0;width: 100%;height: 100%">
    <!-- 加入index下标判断 避免一个页面触发多次调用 -->
    <!--2024-3-29 董晨 去除指定 电梯速查GO面板 需要时候再打开-->
    <el-tabs v-model="curValue" @tab-click="handleClick" type="border-card" :stretch="false" tab-position="top">
      <el-tab-pane v-for="(item, index) in ApplyDataList.filter(x => x.ApplyShow)" :key="item.Id"
        :label="item.ApplyName" :name="item.ApplyName" style="overflow: auto;">
        <MG_HomePage v-if="item.ApplyCode === '10002'" :parentObj="this" :projectId="projectId"></MG_HomePage>
        <div v-else style="width: 100%;height: 83vh">
          <h2>数据统计中心-有待开发内容版块</h2>
        </div>
      </el-tab-pane>
    </el-tabs>
    <div style="position: absolute;top: 6px;right: 10px">
      <el-button size="mini" @click="handleShowSetPage" round icon="el-icon-setting">显示设置</el-button>
      <el-dialog title="应用显示顺序" width="30%" :visible.sync="ApplyListShow">
        <el-table :data="ApplyDataList" ref="table" row-key="ApplyCode" :stripe="true" :show-header="true">
          <el-table-column label="序号" type="index" width="50"></el-table-column>
          <el-table-column prop="ApplyName" label="应用" align="center"></el-table-column>
          <el-table-column label="操作" align="left">
            <template slot-scope="scope">
              <div>
                <el-button :disabled="scope.$index === 0" type="text" @click.stop="upLayer(scope.$index, scope.row)"
                  icon="el-icon-top" size="mini">上移
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button :disabled="scope.$index === ApplyDataList.length - 1" type="text"
                  @click.stop="downLayer(scope.$index, scope.row)" icon="el-icon-bottom" size="mini">下移
                </el-button>
                <el-divider direction="vertical"></el-divider>
                <el-button type="text" @click.stop="ShowOrHideApplyPage(scope.$index, scope.row)" icon="el-icon-bottom"
                  size="mini">
                  {{ scope.row.ApplyShow ? '隐藏' : '显示' }}
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleHideSetPage">取 消</el-button>
          <el-button type="primary" @click="handleSaveSetPage">保 存</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import MG_HomePage from "@/views/Project/DataAnalysis/MerchantsGo/MG_HomePage.vue";

export default {
  name: "DA_HomePage",
  components: {
    MG_HomePage
  },
  props: {
    parentObj: {
      type: Object,
      default() {
        return {}
      }
    },
    projectId: { default: '', type: String }, // 项目ID
  },
  watch: {
    ApplyListShow(newValue, oldValue) {
      if (newValue === false && oldValue === true) {
        this.getDataApplyList()
      }
    }
  },
  data() {
    return {
      ApplyDataList: [],//用于列表展示的面板
      curValue: '', //当前选中面板
      ApplyListShow: false, //应用列表显示
    }
  },
  mounted() {
    this.$nextTick(() => {
      // 进入后调用获取应用列表
      this.getDataApplyList()
    })
  },
  methods: {
    // 面板控制方法
    handleClick(e) {
      this.curValue = e.name;
    },
    //获取所有的应用列表
    getDataApplyList() {
      this.$http.post("/Base_ProjectManage/Project_DAShowList/GetDataListForUserDAList")
        .then((res) => {
          if (res.Success) {
            console.log(res)
            this.ApplyDataList = res.Data.PDLDataList;
            this.curValue = this.ApplyDataList.filter(x => x.ApplyShow)[0].ApplyName;
            console.log('打印应用列表', this.ApplyDataList)
          }
        });
    },
    // 显示页签设置列表
    handleShowSetPage() {
      console.log('打开设置窗口')
      this.ApplyListShow = true
    },
    // 隐藏页签设置列表
    handleHideSetPage() {
      console.log('关闭设置窗口')
      this.ApplyListShow = false
    },
    // 上移操作
    upLayer(index, row) {
      const that = this;
      this.ApplyDataList.forEach((item, index2) => {
        if (item.ApplyCode === row.ApplyCode) {
          if (this.ApplyDataList[index2 - 1] === undefined) {
            that.$message({
              message: "处于顶端，不能继续上移",
              type: "warning"
            });
          } else {
            let upDate = JSON.parse(JSON.stringify(this.ApplyDataList[index2]));
            this.ApplyDataList.splice(index2, 1);
            this.ApplyDataList.splice(index2 - 1, 0, upDate);
          }
        }
      })
      this.curValue = this.ApplyDataList[0].ApplyName
    },
    // 下移操作
    downLayer(index, row) {
      const that = this;
      let index2 = this.ApplyDataList.findIndex(item => item.ApplyCode === row.ApplyCode)
      if (this.ApplyDataList[index2 + 1] === undefined) {
        that.$message({
          message: "处于末端，不能继续上移",
          type: "warning"
        });
        return
      }
      if (index2 >= 0) {
        let upDate = JSON.parse(JSON.stringify(this.ApplyDataList[index2]));
        this.ApplyDataList.splice(index2, 1);
        this.ApplyDataList.splice(index2 + 1, 0, upDate);
      }
      this.curValue = this.ApplyDataList[0].ApplyName
    },
    // 隐藏应用
    ShowOrHideApplyPage(index, row) {
      if (this.ApplyDataList.filter(x => x.ApplyShow).length <= 1 && row.ApplyShow) {
        this.$message({
          message: "至少显示一个应用面板，不能继续隐藏",
          type: "warning"
        });
      } else {
        this.ApplyDataList[index].ApplyShow = !row.ApplyShow;
        this.curValue = this.ApplyDataList.filter(x => x.ApplyShow)[0].ApplyName
      }

    },
    // 保存排序信息
    handleSaveSetPage() {
      if (this.ApplyDataList) {
        this.ApplyDataList.forEach((item, index) => {
          item.Sort = index + 1
        });
        this.$http.post('/Base_ProjectManage/Project_DAShowList/SaveDataByInputDTO', {
          VIPUserId: this.$store.state.userInfo.Id,
          PDLData: this.ApplyDataList
        }).then(resJson => {
          if (resJson.Success) {
            this.$message({
              type: "success",
              message: "应用版块信息保存成功!",
            });
            this.handleHideSetPage()
          } else {
            this.$message({
              type: "error",
              message: resJson.Msg
            });
            this.handleHideSetPage()
          }
        });
      }
    }
  }
}

</script>


<style scoped lang="scss">
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both
}

::v-deep .el-card__body {
  padding: 0;
}

::v-deep .el-tabs__content {
  padding: 5px 5px 0 5px;
  box-sizing: border-box;
  background-color: transparent;
}
</style>
