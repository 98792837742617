<template>
  <!-- //初设阶段-主页列表 -->
  <div>
    <el-button size="mini" type="primary" style="margin-right: 10px" @click="backTask">返回</el-button>

    初设阶段
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    taskObj: { type: Object, require: true },
    ClassType: { type: Number, require: true },
  },
  components: {},
  methods: {
    backTask() {
      this.$emit("task", {
        status: true,
        ...this.taskObj,
      });
    },
  },
  mounted() { },
  created() { },
  computed: {},
  watch: {},
};
</script>
<style lang='scss' scoped></style>